import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { currentUser } from "./helpers";
import "antd/dist/antd.css";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import "axios-progress-bar/dist/nprogress.css";
import { message } from "antd";

message.config({
  top: 80,
});

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/Pages/Login/ResetPassword")
);

class App extends Component {
  componentDidMount() {
    currentUser();
  }
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path="/resetpassword/:token"
              name="ResetPassword"
              render={(props) => <ResetPassword {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
