const initState = {
  authenticated: false,
  user: null,
  accessToken: "",
  employeeId: 0,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGOUT":
      localStorage.removeItem("disl_info"); // Clear localStorage on logout
      return {
        ...state,
        authenticated: false,
        user: null,
        accessToken: "",
        employeeId: 0,
      };

    case "SET_USER":
      const newState = {
        ...state,
        ...action.data,
      };

      // Store the updated state in localStorage
      localStorage.setItem("disl_info", JSON.stringify(newState));

      return newState;

    default:
      return state;
  }
};

export default authReducer;
