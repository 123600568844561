import app_redux_store from "./store/store";

import store from "store";
const Cryptr = require("cryptr");

export const formatPayload = (data = {}) => {
  //return data;

  let formatted = {};
  for (let key in data) {
    if (data[key] instanceof Date) {
      let now = new Date(data[key]);
      let month =
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
      let date = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
      let hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
      let minutes =
        now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
      let seconds =
        now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

      formatted[
        key
      ] = `${now.getFullYear()}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;
    } else formatted[key] = data[key];
  }

  return formatted;
};

export const logout = () => {
  const saved = {
    authenticated: false,
    user: null,
    accessToken: "",
  };
  app_redux_store.dispatch({
    type: "LOGOUT",
    data: saved,
  });
  store.remove("disl_user");
  store.remove("disl_info");
  localStorage.removeItem("disl_user");
  localStorage.removeItem("disl_info");
  localStorage.removeItem("logout-timer");
};

export const save = (response, router) => {
  const saved = {
    authenticated: true,
    user: null,
    accessToken: response.accessToken,
    employeeId: parseInt(response.username),
  };

  app_redux_store.dispatch({
    type: "SET_USER",
    data: saved,
  });

  // reset everything now. fresh data will be loaded from role HOC
  app_redux_store.dispatch({
    type: "ADD_PRIVILEGE",
    data: {
      photo: "",
      role: "",
      all: [],
      privileges: [],
      username: "",
      emlpoyeeId: "",
      userPendingChangeRequest: true,
      howManyChangeApproveNeeded: 0,
      howManyLeaveApproveNeeded: 0,
      employeeCategoryId: 0,
    },
  });

  //get setup status and redirect
  let goToSetup = false;
  const { setup } = app_redux_store.getState();

  if (Object.keys(setup).length === 0) {
    goToSetup = true;
  }

  for (const [key, value] of Object.entries(setup)) {
    if (!value) {
      goToSetup = true;
    }
  }

  // redirect to different route base on role
  if (goToSetup) {
    router.push("/setup");
  } else {
    router.push("/dashboard");
  }
};

// Get Current User
export const currentUser = () => {
  let user = store.get("disl_user");

  if (user !== undefined && user.length > 0) {
    var cryptr = new Cryptr(user);

    try {
      const decryptedUser = cryptr.decrypt(store.get("disl_info"));

      app_redux_store.dispatch({
        type: "SET_USER",
        data: { ...JSON.parse(decryptedUser) },
      });

      return decryptedUser;
    } catch (e) {
      console.log("Not valid here : ", e);
      return {};
    }
  }

  return {};
};

export const slugify = (string) => {
  const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;";
  const b = "aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------";
  const p = new RegExp(a.split("").join("|"), "g");
  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with ‘and’
      // .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
      .replace(/\s/g, "-")
  );
};

export const formatCurrency = (number) => {
  if (!isNaN(number)) {
    return number.toFixed(2);
  } else {
    return 0;
  }
};
