import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "./reducers/authReducer";
import companyReducer from "./reducers/companyReducer";
import privilegeReducer from "./reducers/privilegeReducer";
import setupReducer from "./reducers/setupReducer";
import thunk from "redux-thunk";

// Retrieve persisted state from localStorage
const persistedState = localStorage.getItem("disl_info")
  ? { auth: JSON.parse(localStorage.getItem("disl_info")) }
  : undefined;

const combinedReducers = combineReducers({
  auth: authReducer,
  companies: companyReducer,
  privilege: privilegeReducer,
  setup: setupReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxStore = createStore(
  combinedReducers,
  persistedState, // Initialize the store with persisted state or undefined
  composeEnhancers(applyMiddleware(thunk))
);

export default reduxStore;
