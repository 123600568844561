const initState = {
  company: "3"
};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
};

export default companyReducer;
