const initState = {
  photo: "",
  role: "",
  all: [],
  privileges: [],
  username: "",
  emlpoyeeId: "",
  userPendingChangeRequest: true,
  howManyChangeApproveNeeded: 0,
  howManyLeaveApproveNeeded: 0,
  employeeCategoryId: 0,
  unreadNotificationNumber: 0,
  payrollFinalizeNotificationNumber: 0,
  payrollModificationNotificationNumber: 0,
  ruleChangeEditNotificationNumber: 0,
  pfMembershipApprovalNumber: 0,
  pfLoanApprovalNumber: 0,
};

const priviledgeReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_PRIVILEGE":
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default priviledgeReducer;
